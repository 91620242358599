import Sockette from 'sockette-dynamic-url'
import EventManager from './event-manager'
import Constants from '@emerald-works/constants'
import debug from 'debug'

const logger = debug(`${Constants.COMPONENT_EVENT_BUS_CLIENT}:socket-manager`)
const noop = () => {}
const DEFAULT_OPTIONS = {
  timeout: 5e3,
  maxAttempts: 10,
  triggerTimeout: 30000
}

export default class SocketManager {
  constructor () {
    this.eventManagers = {}
    this.initialisers = {}
    this.connectionStatus = Constants.CONNECTION_SIGNAL_STATUS_RED
    this.payloadsWaitingForConnection = []
    this.opts = { ...DEFAULT_OPTIONS }
  }

  promise () {
    // return Promise
  }

  // red: no connection
  // yellow: connected but not ready to send messages
  // green: connected and ready to use
  isConnected () {
    return this.connectionStatus
  }

  _cleanPayloadWaitingForConnection () {
    this.payloadsWaitingForConnection = []
  }

  _addPayloadWaitingForConnection (payload) {
    this.payloadsWaitingForConnection.push(payload)
  }

  _processPayloadWaitingForConnection () {
    if (
      this.ws &&
      this.connectionStatus === Constants.CONNECTION_SIGNAL_STATUS_GREEN
    ) {
      for (const payload of this.payloadsWaitingForConnection) {
        this.sendJson(payload)
      }
      this._cleanPayloadWaitingForConnection()
    } else {
      console.log(
        'Connection is not ready yet. Not processing payloads in queue to avoid infinity loop.'
      )
    }
  }

  // Send Ping message to prevent API GATEWAY to idle Timeout
  _setPreventIdleTimeout () {
    this.idleTimeout = setTimeout(() => {
      this.sendJson({
        eventName: Constants.PING_EVENT
      })
    }, Constants.PING_REQUEST_IDLE_TIME_MS)
  }

  _resetPreventIdleTimeout () {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout)
    }

    this._setPreventIdleTimeout()
  }

  _setConnectionStatus (status) {
    this.connectionStatus = status
    this.listeners.onConnectionChange(this.connectionStatus)
    if (status === Constants.CONNECTION_SIGNAL_STATUS_GREEN) {
      this._resetPreventIdleTimeout()
      this._processPayloadWaitingForConnection()
    }
  }

  _processInitialiser (message) {
    if (!this.initialisers || Object.keys(this.initialisers).length === 0) {
      console.log('No initialiser registered')
      return
    }
    const event = this.initialisers[message.eventName]
    if (event) {
      if (message.payload && message.payload.error) {
        event.callListeners('onError', message.payload.error)
      } else {
        event.callListeners('onSuccess', message)
      }
    }
  }

  _processInternal (message) {
    logger('processInternal message %j', message)
    const event = this.eventManagers[message.key]
    if (event) {
      if (message.eventName === Constants.SUBSCRIBE_EVENT) {
        event.callListeners('onSubscribe', message)
      }
      if (message.eventName === Constants.UNSUBSCRIBE_EVENT) {
        event.callListeners('onUnsubscribe', message)
      }
    }
  }

  _processEventResponse (message) {
    if (!this.eventManagers) {
      return
    }

    const event = this.eventManagers[message.key]
    if (event) {
      event.cancelTimeout()
      if (message.payload && message.payload.error) {
        event.callListeners('onError', message.payload.error)
      } else {
        event.callListeners('onSuccess', message)
        // if not realtime, unregister
      }

      event.callListeners('onStop', event)
    }
  }

  _processEventSideEffect (message) {
    const targetEvent = Object.values(this.eventManagers).find(
      event => event.eventName === message.eventName
    )

    if (targetEvent) {
      this._processEventResponse({
        ...message,
        key: targetEvent.key
      })
    } else if (
      Object.prototype.hasOwnProperty.call(this.initialisers, message.eventName)
    ) {
      this._processInitialiser(message)
    } else {
      console.info(`
        Trying to process event sideEffect for event name: ${message.eventName} but no event was found for it in eventManagers or initialisers. 
        Create a event to handle it in the component context or add it as a initialiser.
      `)
    }
  }

  reconnect () {
    this.sockette.reconnect()
  }

  connect ({
    eventBusURL,
    namespace,
    onOpen = noop,
    onConnectionChange = noop,
    onReconnect = noop,
    onMaximum = noop,
    onClose = noop,
    onError = noop,
    options
  }) {
    this.eventBusURL = eventBusURL
    this.namespace = namespace
    this.listeners = {
      onOpen: (...args) => {
        logger('onOpen', args)
        onOpen(args)
      },
      onConnectionChange: connectionStatus => {
        logger('onConnectionChange', connectionStatus)
        onConnectionChange(connectionStatus)
      },
      onReconnect: (...args) => {
        logger('onReconnect', args)
        onReconnect(args)
      },
      onMaximum: (...args) => {
        logger('onMaximum', args)
        onMaximum(args)
      },
      onClose: (...args) => {
        logger('onClose', args)
        onClose(args)
      },
      onError: (...args) => {
        logger('onError', args)
        onError(args)
      }
    }

    this.opts = {
      ...DEFAULT_OPTIONS,
      ...options
    }

    this.sockette = new Sockette(this.eventBusURL, {
      ...this.opts,
      onopen: event => {
        setTimeout(() => {
          this.ws = event.target
          this._setConnectionStatus(Constants.CONNECTION_SIGNAL_STATUS_YELLOW)
          this.listeners.onOpen()
        }, 10)
      },
      onmessage: ({ data }) => {
        this._resetPreventIdleTimeout()
        const message = JSON.parse(data)
        logger('onmessage type %o message %j', message.type, message)
        const onMessageHandlers = {
          [Constants.MESSAGE_TYPE_CONNECTION_SIGNAL]: () => {
            this._setConnectionStatus(message.payload.connectionSignal)
          },
          [Constants.MESSAGE_TYPE_RESPONSE]: () => {
            // Default event result
            if (message.key) {
              this._processEventResponse(message)
            } else {
              // sideEffect eventLM result
              this._processEventSideEffect(message)
            }
          },
          [Constants.MESSAGE_TYPE_ACK]: () => {
            if (!this.eventManagers) {
              return
            }

            const eventLM = this.eventManagers[message.key]
            if (eventLM) {
              eventLM.callListeners('onAck', message.payload)

              if (
                message.payload.responseNeedsSubscribe &&
                message.payload.responseDestination !== eventLM.name
              ) {
                eventLM.cancelTimeout()
                eventLM.callListeners('onStop', eventLM)
              }
            }
          },
          [Constants.MESSAGE_TYPE_INITIALISER]: () => {
            this._processInitialiser(message)
          },
          [Constants.MESSAGE_TYPE_INTERNAL]: () => {
            this._processInternal(message)
          }
        }
        logger('message type %o', message.type)
        if (
          Object.prototype.hasOwnProperty.call(onMessageHandlers, message.type)
        ) {
          onMessageHandlers[message.type]()
        } else {
          logger('No processing for message', message)
        }
      },
      onreconnect: this.listeners.onReconnect || noop,
      onmaximum: this.listeners.onMaximum || noop,
      onclose: event => {
        this.ws = undefined
        if (
          this.connectionStatus !== Constants.CONNECTION_SIGNAL_STATUS_RELOADING
        ) {
          this._setConnectionStatus(Constants.CONNECTION_SIGNAL_STATUS_CLOSED)
        }
        this.listeners.onClose ? this.listeners.onClose(event) : noop()
      },
      onerror: err => {
        this.listeners.onError ? this.listeners.onError(err) : noop()
      }
    })
  }

  disconnect () {
    this._setConnectionStatus(
      Constants.CONNECTION_SIGNAL_STATUS_MANUALLY_DISCONNECTED
    )
    setTimeout(() => {
      try {
        if (this.ws) this.ws.close()
      } catch (err) {
        // TODO narrow down possible errors
        console.log('err', err)
      }
    }, 100)
  }

  reloadConnection () {
    try {
      this._setConnectionStatus(Constants.CONNECTION_SIGNAL_STATUS_RELOADING)
      try {
        if (this.ws) this.ws.close()
      } catch (err) {
        // TODO narrow down possible errors
        console.log('error closing the connection', err)
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  createEventManager (eventParams) {
    // initialiser can't subscribe
    const initialiserParams = {
      ...eventParams,
      connection: this,
      canSubscribe: false,
      subscribeOnInit: false,
      initialiser: true
    }
    const nonInitialiserParams = {
      connection: this,
      ...eventParams
    }
    const normalizedParams = eventParams.isInitialiser
      ? initialiserParams
      : nonInitialiserParams

    const event = new EventManager(normalizedParams)
    if (eventParams.isInitialiser) {
      this.initialisers[event.eventName] = event
    } else {
      this.eventManagers[event.key] = event
    }

    return event
  }

  unregister (event) {
    // console.log(event)
  }

  sendJson (payload) {
    if (
      this.ws &&
      this.connectionStatus === Constants.CONNECTION_SIGNAL_STATUS_GREEN
    ) {
      this.ws.send(JSON.stringify(payload))
      this._resetPreventIdleTimeout()
    } else {
      this._addPayloadWaitingForConnection(payload)
    }
  }
}
